/* THE FOUR STEPS TO BEING AN AWESOME UI ENGINEER (AND CO-CODER)
1. Try to keep custom styles to a minimum.
2. Be aware that these styles will affect your entire application.
3. Namespace your own custom styles with something
related to your app, for example, .spark-classname or .apollo-classname.
4. Use Canvas global variables for color, spacing and more.
They are CSS variables that are already globally available in the browser.
The full list is located here: https://canvas.prod.target.com/design-tokens. */

$mainHeaderHeight: 56px;

.praxcss-height100vh {
  height: 100vh;
}

.praxcss-avatarButton {
  border: 0;
  padding: 0;
  border-radius: var(--canvas-border-radius--expanded);
}

.topPage-layout-header {
  height: $mainHeaderHeight;
}

.topPage-layout-body {
  height: calc(100vh - $mainHeaderHeight);
}

.topPage-container {
  height: 100%;
  margin-top: 12px;
}

.fullHeight-container {
  height: 100%;
}

.stop-events-table {
  .C-TableAdvanced {
    width: 100%;
  }
  display: inline-flex;
}

.shipment-table {
  .C-TableAdvanced {
    width: 100%;
  }
}

.white-header {
  color: white;
}

.form-field {
  padding: 10px;
  margin: 10px;
}

.C-TableAdvanced__bodyCell {
  vertical-align: inherit;
  .C-Input--type-label {
    display: none;
  }
}
.form-button {
  margin: 10px;
}

.full-width-modal {
  .C-Modal__container {
    min-width: 97vw;
  }
}

.default-dashboard-menu-name {
  display: flex;
  flex-grow: 1;
}

.default-dashboard-actions {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.default-dashboard-text{
  min-height: 70px;
  min-width: 400px;
  margin: 0 16px;
}

.default-dashboard-menu-item {
  display: flex;
}

.load-details-container {
  padding-top: 32px;
  padding-right: 32px;
  padding-bottom: 0px;
  padding-left: 32px;
}

.centering-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.C-TableAdvanced__bodyCell {
  vertical-align: inherit;
}

.C-TableAdvancedContainer {
  max-height: 54vh
}

.blue-chip {
  background: var(--theme-colors-interactive, #3E72DB);
  color: var(--canvas-global-color--white);
}

.header-icon-button {
  color: white;
  background-color: rgba(0,0,0,0);
  border: 0;
  padding: 0;
}

.header-icon-button:hover {
  background-color: rgba(0,0,0,0.1);
}

@media (min-width: 1600px) {
  // FM Dashboard
  .first-card {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .first-item {
    padding-right: 0!important;
  }
  .second-card {
    border-radius: 0;
  }
  .second-item {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .third-card {
    border-radius: 0;
  }
  .third-item {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .fourth-card {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .fourth-item {
    padding-left: 0!important;
  }

  // MM Dashboard, 4x2
  .mm-overview {
    .first-item {
      padding-right: 0!important;
      .dashboard-card {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .second-item {
      padding-left: 0!important;
      padding-right: 0!important;
      .dashboard-card {
        border-radius: 0;
      }
    }
    .third-item {
      padding-left: 0!important;
      padding-right: 0!important;
      .dashboard-card {
        border-radius: 0;
      }
    }
    .fourth-item {
      padding-left: 0!important;
      .dashboard-card {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
  .fifth-item {
    padding-right: 0!important;
    padding-top: 0!important;
    .dashboard-card {
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .sixth-item {
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: 0!important;
    .dashboard-card {
      border-radius: 0;
    }
  }
  .seventh-item {
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: 0!important;
    .dashboard-card {
      border-radius: 0;
    }
  }
  .eighth-item {
    padding-left: 0!important;
    padding-top: 0!important;
    .dashboard-card {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}


@media (min-width: 768px) and (max-width: 1599px) {
  // FM Dashboard
  .first-card {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .first-item {
    padding-right: 0!important;
  }
  .second-card {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
  }
  .second-item {
    padding-left: 0!important;
  }
  .third-card {
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .third-item {
    padding-right: 0!important;
  }
  .fourth-card {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .fourth-item {
    padding-left: 0!important;
  }

  // MM Dashboard, 2x4
  .mm-overview {
    .first-item {
      padding-right: 0!important;
      .dashboard-card {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .second-item {
      padding-left: 0!important;
      .dashboard-card {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
    .third-item {
      padding-top: 0!important;
      padding-right: 0!important;
      .dashboard-card {
        border-radius: 0;
      }
    }
    .fourth-item {
      padding-top: 0!important;
      padding-left: 0!important;
      .dashboard-card {
        border-radius: 0;
      }
    }
  }
  .fifth-item {
    padding-right: 0!important;
    padding-top: 0!important;
    .dashboard-card {
      border-radius: 0;
    }
  }
  .sixth-item {
    padding-left: 0!important;
    padding-top: 0!important;
    .dashboard-card {
      border-radius: 0;
    }
  }
  .seventh-item {
    padding-right: 0!important;
    padding-top: 0!important;
    .dashboard-card {
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .eighth-item {
    padding-left: 0!important;
    padding-top: 0!important;
    .dashboard-card {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

@media (max-width: 767px) {
  // MM Dashboard, 1x8
  .mm-overview {
    .first-item {
      .dashboard-card {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .second-item {
      padding-top: 0!important;
      .dashboard-card {
        border-radius: 0;
      }
    }
    .third-item {
      padding-top: 0!important;
      .dashboard-card {
        border-radius: 0;
      }
    }
    .fourth-item {
      padding-top: 0!important;
      .dashboard-card {
        border-radius: 0;
      }
    }
  }
  .fifth-item {
    padding-top: 0!important;
    .dashboard-card {
      border-radius: 0;
    }
  }
  .sixth-item {
    padding-top: 0!important;
    .dashboard-card {
      border-radius: 0;
    }
  }
  .seventh-item {
    padding-top: 0!important;
    .dashboard-card {
      border-radius: 0;
    }
  }
  .eighth-item {
    padding-top: 0!important;
    .dashboard-card {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

@media (prefers-color-scheme: light) {
  .table-action-button {
    color: black;
    background-color: rgba(0,0,0,0);
    border: 0;
    padding: 0;
  }
  .table-action-button:hover {
    background-color: rgba(0,0,0,0.1);
  }
  .table-action-button:disabled {
    background-color: rgba(0,0,0,0);
  }
}

@media (prefers-color-scheme: dark) {
  .table-action-button {
    color: white;
    background-color: rgba(0,0,0,0);
    border: 0;
    padding: 0;
  }
  .table-action-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .table-action-button:disabled {
    background-color: rgba(0,0,0,0);
  }
}
